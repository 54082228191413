@font-face {
  font-family: "Barlow";
  src: url("/assets/fonts/Barlowregular.ttf");
}
@font-face {
  font-family: "Barlow";
  src: url("/assets/fonts/Barlowbold.ttf");
  font-weight: bold;
}
.ant-popover-inner-content {
  padding: 0px !important;
}
.ant-popover-inner {
  border-radius: 6px !important;
}
.ant-modal {
  width: fit-content !important;
}
.ant-modal-content {
  border-radius: 6px !important;
}
.ant-modal-body {
  padding: 0px !important;
}
.ant-upload-drag {
  background: none !important;
  border: 2px dashed #a0a5b9 !important;

  border-radius: 6px !important;
}
.adsk-viewing-viewer {
  height: calc(100% - 64px) !important;
}
.dark-theme.quality-text {
  height: calc(100% - 64px) !important;
}
.ant-input-affix-wrapper {
  border-radius: 4px !important;
}
.ant-collapse-header {
  padding: 0px !important;
  user-select: none !important;
  display: flex !important;
  align-items: center !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
.ant-collapse-content-box {
  padding: 0px !important;
}
.ant-collapse-header .ant-collapse-arrow {
  left: unset !important;
  right: 1px !important;
}
.ant-select-selector {
  align-items: center !important;
  height: 40px !important;
  border-radius: 4px !important;
}
.ant-select-arrow {
  top: 65% !important;
}
.ant-collapse-header .ant-collapse-arrow {
  top: unset !important;
}
.DayPicker {
  background: #5f6679 !important;
  border-radius: 4px !important;
}
.DayPicker-Caption {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #ffffff !important;
}
.DayPicker-NavButton {
  top: unset !important;
  right: 0px !important;
}
.DayPicker-NavButton--prev {
  transform: rotate(180deg);
}
.DayPicker-Month {
  margin: 0px !important;
}
.DayPicker-Weekday {
  min-width: 24px !important;

  height: 24px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #ffffff !important;

  padding: 8px !important;
}
.DayPicker-Day {
  min-width: 24px !important;
  height: 24px !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #ffffff !important;
  border-radius: 4px !important;
  padding: 8px !important;
  &:hover {
    background-color: #5f80f5 !important;
  }
}
.DayPicker-Day--start {
  background-color: #5f80f5 !important;
  border-radius: 4px 0px 0px 4px !important;
}
.DayPicker-Day--end {
  background-color: #5f80f5 !important;
  border-radius: 0px 4px 4px 0px !important;
}
.DayPicker-Day--outside {
  color: #a0a5b9 !important;
  opacity: 0.5 !important;
}
.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: rgba(#5f80f5, 0.3) !important;
}
.DayPicker-NavButton {
  width: 16px !important;
  height: 16px !important;
  background: url("/assets/icons/arrows/ArrowRightSmallWhite.svg") center
    no-repeat no-repeat !important;
}

.ant-popover.Calendar.ant-popover-placement-bottomRight {
  .ant-popover-content {
    .ant-popover-arrow {
      display: none !important;
    }
  }
}
mark {
  background-color: #ffffff !important;
}
body {
  color: #1e283a !important;
  margin: 0px;
}
body * {
  font-family: "Barlow";
  box-sizing: border-box;
}

@import "src/styles/buttons.scss";
@import "src/styles/divider.scss";
@import "styles/forge";
