.content {
  min-height: 100vh;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 100vh;
  background-color: #1e283a;
  color: #ffffff;
  .title {
    color: inherit;
    font-size: 70px;
    font-weight: 500;
    text-align: center;
  }

  .subTitle {
    font-size: 25px;
    color: inherit;
    text-align: center;
  }

  .inputWrap {
    display: flex;
    max-width: 500px;
    width: 100%;
    margin-top: 40px;
    .button {
      margin-left: 20px;
      min-width: 200px;
    }
  }
}

@media (max-width: 992px) {
  .content {
    .title {
      font-size: 37px;
    }
    .subTitle {
      font-size: 20px;
    }
    .inputWrap {
      flex-direction: column;
      max-width: 300px;
      margin-top: 40px;
      .button {
        margin: auto;
        margin-top: 20px;
      }
    }
  }
}
