.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  border-radius: 4px;
  padding: 0px 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  &.purple {
    background: #5f80f5;
    color: #ffffff;
  }
  &.black {
    background: #1e283a;
    color: #ffffff;
  }
  &.purple-inline {
    border: 1px solid #5f80f5;
    box-sizing: border-box;
    border-radius: 4px;
    color: #5f80f5;
  }
  &:hover {
    box-shadow: 0px 0px 3px #1e283a;
  }
  &:active {
    box-shadow: 0px 0px 6px #1e283a;
  }
}
