//Marker
.marker-3d {
  display: block;
  position: absolute;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  width: 16px;
  height: 16px;

  .marker-tooltip {
    transform: translateX(calc(-50% + 16px));
    top: 34px;
    padding: 10px 16px;
    background-color: white;
    position: absolute;
    display: none;
    font-family: "Barlow", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    border-radius: 4px;
    color: #1E283A;
    white-space: nowrap;

    &:before {
      content: '';
      top: -8px;
      left: 50%;
      transform: translateX(calc(-50% - 3px));
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent #ffffff transparent;
    }
  }
}
